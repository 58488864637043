<!-- 学情K12老师搜索班级 -->
<template>
  <div>
    <span>选择班级：</span>
    <el-select v-model="classValue" placeholder="请选择" size="mini" @change="searchSituation">
      <el-option
        v-for="item in classOptions"
        :key="item.id"
        :label="item.className"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-select
      v-model="activityTypeValue"
      class="sel"
      placeholder="请选择活动类型"
      size="mini"
      @change="caseIdSelect"
    >
      <el-option
        v-for="item in activityTypeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { classList } from '@/api/api'
export default {
  data () {
    return {
      classValue: '',
      classOptions: [],
      className: null,
      activityTypeOptions: [],
      activityTypeValue: null,
      immexChart: false,
      loading: true,
      immexOptions: []
    }
  },
  components: {},
  mounted () {
    this.loadClass();
    this.analysis()
  },
  methods: {
    // 解析appIds
    analysis () {
      if (this.utils.identity('K12学情详情')) {
        this.activityTypeOptions.push({
          value: 0,
          label: 'K12'
        })
      }
      if (this.utils.identity('IMMEX学情详情')) {
        this.activityTypeOptions.push({
          value: 1,
          label: 'IMMEX'
        })
      }
      if (this.utils.identity('标签学情详情')) {
        this.activityTypeOptions.push({
          value: 2,
          label: '标签'
        })
      }
      this.activityTypeValue = this.activityTypeOptions[0].value
      this.$emit('statistics_type', this.activityTypeValue)
    },
    // 选择班级
    searchSituation (val) {
      this.$emit('searchClass', this.classValue)
      let info = this.classOptions.find(item => { return item.id == this.classValue })
      this.$emit('class_info', info)
      this.getTeacherImmxActiveCase(this.classValue)
    },
    // 获取班级
    loadClass () {
      this.loading = true
      classList({}).then(res => {
        if (res.code == 0) {
          this.loading = false
          if (res.data.list.length > 0) {
            this.classOptions = res.data.list
            if (this.$store.state.manage_classId) {
              this.classValue = Number(this.$store.state.manage_classId)
              let info = this.classOptions.find(item => { return item.id == this.classValue })
              this.$emit('class_info', info)
            } else {
              this.classValue = this.classOptions[0].id
              this.$emit('class_info', this.classOptions[0])
            }
            this.getTeacherImmxActiveCase(this.classValue)
            this.$emit('searchClass', this.classValue)
          }
        }
      })
    },
    // 获取caseid
    getTeacherImmxActiveCase (classId) {
      this.$api.page.getTeacherImmxActiveCase({
        "classId": classId
      }).then((res) => {
        if (res.data.length > 0) {
          this.immexOptions = res.data.data
          this.immexValue = this.immexOptions[0].activeId

          let arr = this.immexOptions.filter((item) => {
            return item.activeId == this.immexValue
          })
          this.$emit('searchCaseId', arr[0])
        }
      })
    },
    // 选择活动类型
    caseIdSelect (val) {
      let arr = []
      if (this.immexOptions.length > 0) {
        arr = this.immexOptions.filter((item) => {
          return item.activeId == val
        })
        this.$emit('searchCaseId', arr[0])
      }
      this.$emit('statistics_type', this.activityTypeValue)
    }
  }
}

</script>
<style lang='less' scoped>
@import '../../../style/mouse.less';
.sel {
  margin-left: 30px;
}
</style>
