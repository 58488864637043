<!-- 学生变式统计表 -->
<template>
  <div>
    <!-- 章节变式统计表 -->
    <el-card style="border:none;box-shadow:none">
      <span class="card-body-title">章节变式统计</span>
      <span>请选择章节：</span>
      <el-select v-model="chapterValue" placeholder="请选择章节" size="mini" @change="change_chapter">
        <el-option
          v-for="item in chapterOptions"
          :key="item.chapterId"
          :label="item.chapterName"
          :value="item.chapterId"
        ></el-option>
      </el-select>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @row-click="click_row"
        v-loading="loading"
        element-loading-text="拼命统计中"
        @sort-change="click_sort"
      >
        <el-table-column prop="name" label="学生名称"></el-table-column>
        <el-table-column prop="caseCount" label="布置变式数量"></el-table-column>
        <el-table-column prop="onceCorrectCount" label="一次做对次数"></el-table-column>
        <el-table-column prop="twiceCorrectCount" label="二次做对次数"></el-table-column>
        <el-table-column prop="twiceIncorrectCount" label="两次做错次数"></el-table-column>
        <el-table-column prop="unfinishedCount" label="未完成次数"></el-table-column>
        <el-table-column prop="accuracy" label="章节最后一次内化率" sortable="custom"></el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="page.currentPage"
        :page-size="page.pageSize"
        :total="page.total"
        @current-change="clickCurrentPage($event,0)"
      ></el-pagination>
    </el-card>
    <!-- 章节变式详情表 -->
    <el-card style="border:none;box-shadow:none">
      <span class="card-body-title">章节变式详情</span>
      <p style="margin-bottom:10px">学生姓名：{{studentName}}</p>
      <div class="icon_student">
        <span class="iswrong" :class="icon_class[0]"></span>
        <span class="icon_text">一次做对</span>
        <span class="iswrong yes_yellow" :class="icon_class[1]"></span>
        <span class="icon_text">二次做对</span>
        <span class="iswrong wrong_red" :class="icon_class[2]"></span>
        <span class="icon_text">两次做错</span>
        <span class="iswrong incomplete" :class="icon_class[3]"></span>
        <span class="icon_text">未完成</span>
      </div>
      <el-table
        :data="studentTableData"
        border
        style="width: 100%"
        v-loading="studentLoading"
        element-loading-text="拼命统计中"
      >
        <el-table-column prop="caseSN" label="变式名称" width="140"></el-table-column>
        <el-table-column prop="caseCount" label="布置次数" width="100"></el-table-column>
        <el-table-column prop="onceCorrectCount" label="一次做对次数" width="120"></el-table-column>
        <el-table-column prop="twiceCorrectCount" label="二次做对次数" width="120"></el-table-column>
        <el-table-column prop="twiceIncorrectCount" label="两次做错次数" width="120"></el-table-column>
        <el-table-column prop="unfinishedCount" label="未完成次数" width="120"></el-table-column>
        <el-table-column prop="accuracy" label="最后一次内化率" width="150"></el-table-column>
        <el-table-column label="做题情况">
          <template slot-scope="scope">
            <span
              class="iswrong"
              :class="icon_class[item]"
              v-for="(item,index) in scope.row.type"
              :key="index"
              style="margin-left:5px"
            ></span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="pageStudent.currentPage"
        :page-size="pageStudent.pageSize"
        :total="pageStudent.total"
        @current-change="clickCurrentPage($event,1)"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { SectionAnalysisTable, SectionAnalysisDetails, ArrangeChapter } from '@/api/api'
export default {
  props: {
    class_son_info: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      tableData: [],//章节变式统计表格数据
      studentTableData: [],//章节变式详情表格数据
      chapterValue: '',//下拉框选中
      chapterOptions: [],//章节下拉框
      classData: {},//传进来的班级信息
      studentName: '',//选中的学生姓名
      icon_class: ['yes_green', 'yes_yellow', 'wrong_red', 'incomplete'],//学生答题情况的图标数组
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },//章节变式统计分页
      loading: false,//第一个表加载
      studentLoading: false,//第二个表架子啊
      pageStudent: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },//章节变式详情表分页
    }
  },
  watch: {
    class_son_info (val, old) {
      if (val !== old) {
        this.chapterOptions = []
        this.chapterValue = ''
        this.classData = val
        this.loadchapter()
      }
    }
  },
  components: {},
  mounted () {
  },
  methods: {
    // 表格排序
    click_sort (val) {
      //ascending 从高到低
      //descending 从低到高
      //null 默认
      if (val.column.order == "descending") {
        this.loadSectionAnalysisTable(6)
      } else if (val.column.order == "ascending" || val.column.order == null) {
        this.loadSectionAnalysisTable(5)
      }
    },
    // 点击表格分页
    clickCurrentPage (val, type) {
      // 0  章节变式统计分页
      // 1  章节变式详情表分页
      if (type == 0) {
        this.page.currentPage = val
        this.loadSectionAnalysisTable()
      } else {
        this.pageStudent.currentPage = val
        this.loadStudentTableData(this.total_change)
      }
    },
    // 点击第一个表
    click_row (val) {
      this.total_change = val
      this.loadStudentTableData(this.total_change)
      this.studentName = val.name
    },
    // 选中章节
    change_chapter () {
      this.loadSectionAnalysisTable()
    },
    // 加载当前班级的章节列表
    loadchapter () {
      this.loading = true
      let params = {
        "classId": this.classData.id
      }
      ArrangeChapter(params).then(res => {
        this.loading = false
        if (res.code == 0) {
          this.chapterOptions = res.data.list
          if (res.data.list.length > 0) {
            this.chapterValue = res.data.list[0].chapterId
          }
          this.loadSectionAnalysisTable(5)
        }
      })
    },
    // 第一张表数据加载
    loadSectionAnalysisTable (val) {
      this.loading = true
      let params = {
        "pageSize": this.page.pageSize,
        "pageNum": this.page.currentPage,
        "classId": this.classData.id,  //班级id
        "setId": this.chapterValue,  //章节id
        "orderStatus": val,//内化率排序查询  5正序  6倒序
      }
      SectionAnalysisTable(params).then(res => {
        this.loading = false
        if (res.code == 0) {
          res.data.list.map(item => {
            item.accuracy = (item.accuracy * 100).toFixed(2) + '%'
          })
          this.page.total = res.data.total
          this.tableData = res.data.list
          this.loadStudentTableData(this.tableData[0])
        }
      })
    },
    // 第二张表数据
    loadStudentTableData (val) {
      if (val) {
        this.studentLoading = true
        let param = {
          "pageSize": this.pageStudent.pageSize,
          "pageNum": this.pageStudent.currentPage,
          "classId": this.classData.id,  //班级id
          "setId": this.chapterValue,  //章节id
          "userId": val.id  //用户id
        }
        this.studentName = val.name
        SectionAnalysisDetails(param).then(res => {
          this.studentLoading = false
          if (res.code == 0) {
            res.data.list.map(item => {
              item.accuracy = (item.accuracy * 100).toFixed(2) + '%'
            })
            this.studentTableData = res.data.list
            this.pageStudent.total = res.data.total
          }
        })
      }
    },
  }
}
</script>
<style lang='less' scoped>
@import '../style/studentVersionTable.less';
@import '../../../style/mouse.less';
</style>
