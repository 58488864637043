<!-- 标签活动统计 -->
<template>
  <div style="height: 100%">
    <el-card class="card-footer">
      <span class="card-body-title">班级活动统计</span>
      <span>选择标签模板：</span>
      <el-select v-model="templateValue" placeholder="请选择" size="mini">
        <el-option
          v-for="item in templateOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="ids"
        border
        :tree-props="{ children: 'returnDOList', hasChildren: 'hasChildren' }"
      >
        <!--   @row-click="click_row"     row-key="id"-->
        <el-table-column prop="title" label="活动/任务"></el-table-column>
        <el-table-column prop="allTag" label="任务数量"></el-table-column>
        <el-table-column prop="allUser" label="参训人数"></el-table-column>
        <el-table-column prop="result" label="标定结果"></el-table-column>
        <el-table-column prop="createTime" label="更新时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              @click="handleExamine(scope.$index, scope.row)"
              >查看</el-button
            > -->
            <el-button
              size="mini"
              type="danger"
              @click="handleData2Excel(scope.$index, scope.row)"
              >导出</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="Total"
        :current-page="pageNum"
        :page-size="pageSize"
        @current-change="paging"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { templateList, LabelActive, LabelMessage } from "../../../api/api";
import { dataConversionUtil } from "../../../util/data2Excel.js";
export default {
  props: {
    searchClassId: {
      type: Number,
    },
    class_son_info: {
      type: Object,
    },
  },
  data() {
    return {
      tableData: [],
      templateOptions: [],
      templateValue: "",
      classId: null,
      tableId: 1,
      pageSize: 10,
      pageNum: 1,
      Total: 0,
    };
  },
  watch: {
    searchClassId(val, old) {
      if (val !== old) {
        this.classId = val;
        this.showTemplateList();
      }
    },
    class_son_info(val, old) {
      if (val !== old) {
        this.showTemplateList();
      }
    },
    templateValue(val, old) {
      if (val !== old) {
        if (val == "") {
        } else {
          LabelActive({
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            classId: this.classId,
            templateId: this.templateValue,
          }).then((res) => {
            var id = 0;
            this.Total = res.data.total;
            res.data.list.map((item, index) => {
              item["ids"] = ++id;
              if (item.returnDOList.length > 0) {
                item.returnDOList.map((ite, i) => {
                  ite["ids"] = ++id;
                  ite.createTime = this.duration.getDateStr(ite.createTime);
                });
              }
            });
            this.tableData = res.data.list;
          });
        }
      }
    },
  },
  components: {},
  mounted() {
    this.showTemplateList();
    // this.loadActiveList(this.searchClassId)
  },
  methods: {
    //分页
    paging(val) {
      this.pageNum = val;
      LabelActive({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        classId: this.classId,
        templateId: this.templateValue,
      }).then((res) => {
        this.Total = res.data.total;
        var id = 0;
        res.data.list.map((item, index) => {
          item["ids"] = ++id;
          if (item.returnDOList.length > 0) {
            item.returnDOList.map((ite, i) => {
              ite["ids"] = ++id;
              ite.createTime = this.duration.getDateStr(ite.createTime);
            });
          }
        });
        this.tableData = res.data.list;
      });
    },
    //导出
    handleData2Excel(index, row) {
      var type = 0; //0代表活动  1代表题目
      if (row.returnDOList) {
        type = 0;
      } else {
        type = 1;
      }
      var name = "您确定导出本次活动吗？";
      if (type == 1) {
        name = "您确定导出该题目吗？";
      }
      this.$confirm(name, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var obj = {};
          let arr = [["题目", "题目id", "姓名"]];
          let dataList = [];
          if (type == 0) {
            //活动的
            obj = { activityId: row.id };
          } else {
            //变式的
            obj = { taskId: row.id };
          }
          LabelMessage(obj).then((res) => {
            res.data[0].label.map((item) => {
              if (item.type == 2 || item.type == 3) {
                //单选和多选拆分
                var a = item.content.split(",");
                a.map((t, i) => {
                  t = "(" + item.templateNmae + ")" + t;
                  arr[0].push(t);
                });
              } else {
                arr[0].push(item.templateNmae);
              }
            });
            res.data[0].userMessage.map((item, index) => {
              item.userLalbeMessage.map((ite, ind) => {
                var list = [];
                list.push(item.topicName); //题目
                list.push(item.topicId); //id
                list.push(ite.userName);
                ite.topicLableReturnDOS.map((a, i) => {
                  if (a.type == 2 || a.type == 3) {
                    var content = a.content.split(","); //选项
                    var answer = a.answer.split(","); //答案
                    content.map((option) => {
                      var num = 0;
                      answer.map((answers) => {
                        if (answers == option) {
                          num = 1;
                        }
                      });
                      list.push(num);
                    });
                  } else {
                    list.push(a.answer);
                  }
                });
                dataList.push(list);
              });
            });
            dataConversionUtil.dataToExcel("标签答题详情表", arr, dataList);
          });

          this.$message({
            type: "success",
            message: "导出中...",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消导出",
          });
        });
    },
    // 点击表格
    handleExamine(index, row) {
      this.$router.push({ path: "/tagChart" });
    },
    // 标签模板列表
    showTemplateList() {
      this.templateOptions = [];
      this.templateValue = "";
      templateList({
        pageSize: null,
        pageNum: null,
        gradeId: this.class_son_info.grade,
        subjectsId: this.class_son_info.subjects,
      }).then((res) => {
        if (res.data) {
          this.templateOptions = res.data.list;
          if (this.templateOptions.length > 0) {
            this.templateValue = this.templateOptions[0].id;
          }
        }
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../style/mouse.less";
.card-footer {
  height: 100%;
  // margin-right: 30px;
  margin-top: 10px;
}
.card-body-title {
  display: inline-block;
  border-left: 5px solid #409eff;
  padding-left: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
}
</style>
