<!-- 班级知识点统计表 -->
<template>
  <div>
    <el-card style="border:none;box-shadow:none">
      <span class="card-body-title">班级变式统计</span>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @row-click="clickTable"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{textAlign: 'center'}"
        v-loading="loading"
        element-loading-text="拼命统计中"
      >
        <el-table-column fixed prop="special" label="知识点名称（即：专题名称）" width="150"></el-table-column>
        <el-table-column fixed prop="variantNumber" label="变式号" width="150"></el-table-column>
        <el-table-column :label="item" v-for="(item,index) in tableArr" :key="index">
          <el-table-column label="布置时间">
            <template slot-scope="{row}">
              <div v-for="(itm,inx) in row.list" :key="inx" v-if="inx == index">{{itm.startTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="1次对人数">
            <template slot-scope="{row}">
              <div
                v-for="(itm,inx) in row.list"
                :key="inx"
                v-if="inx == index"
              >{{itm.onceCorrectCount}}</div>
            </template>
          </el-table-column>
          <el-table-column label="第2次对人数">
            <template slot-scope="{row}">
              <div
                v-for="(itm,inx) in row.list"
                :key="inx"
                v-if="inx == index"
              >{{itm.twiceCorrectCount}}</div>
            </template>
          </el-table-column>
          <el-table-column label="2次都错人数">
            <template slot-scope="{row}">
              <div
                v-for="(itm,inx) in row.list"
                :key="inx"
                v-if="inx == index"
              >{{itm.twiceIncorrectCount}}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column fixed="right" prop="accuracy" label="最后一次的平均内化率" width="150"></el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="page.pageSize"
        layout="total, prev, pager, next"
        :total="page.total"
        background
        style="margin-top:10px"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { StudyStatistics } from '@/api/api'
export default {
  props: {
    loadclassId: {
      type: Number
    }
  },
  data () {
    return {
      tableData: [],//表格数据
      tableArr: [],//表格子表头
      page: {
        pageSize: 5,
        pageNum: 1,
        total: 0
      },
      classId: null,//班级id
      throttle: true,//节流字段
      loading: false,//加载提示
    }
  },
  watch: {
    loadclassId (val, old) {
      if (val !== old) {
        this.classId = val
        this.loadData()
      }
    }
  },
  components: {},
  mounted () {

  },
  methods: {
    loadData () {
      this.loading = true
      let param = {
        'classId': this.classId,
        "pageSize": this.page.pageSize,
        'pageNum': this.page.pageNum
      }
      StudyStatistics(param).then(res => {
        this.loading = false
        if (res.code == 0) {
          this.tableArr = []
          // 拿出长度最长的变式
          let arr = []
          res.data.list.map((item, index) => {
            item.list.sort((atime, btime) => {
              return Date.parse(atime.startTime) - Date.parse(btime.startTime)
            })
            item.list.forEach(itm => {
              itm.startTime = this.timestampToTime(Date.parse(itm.startTime))
              item.accuracy = (itm.accuracy * 100).toFixed(2) + '%'
            })
            if (item.caseSN) {
              let variantNumber = item.caseSN.split(' ')[0]
              item.variantNumber = variantNumber
            }
            if (arr.length == 0) {
              arr = item.list
            } else if (arr.length < item.list.length) {
              arr = item.list
            }
          })
          arr.forEach((item, index) => {
            this.tableArr.push(`第  ${index + 1}  次布置`)
          })
          this.tableData = res.data.list
          this.page.total = res.data.total
        }
      })
    },
    handleCurrentChange (val) {
      this.page.pageNum = val
      this.loadData()
    },
    // 点击表格行
    clickTable (val) {
      let choice_data = val.list.find(item => {
        return item.onceCorrectCount !== 0 || item.twiceCorrectCount !== 0 || item.twiceIncorrectCount !== 0
      })
      if (choice_data) {
        let param = {
          variantNumber: val.variantNumber,
          taskId: val.taskId,
          id: val.id,
          classId: this.classId
        }
        this.$router.push({ path: '/statisticalTable', query: param })
      } else {
        if (this.throttle) {
          this.throttle = false
          this.$message.error('该变式暂无人答题！');
          setTimeout(() => {
            this.throttle = true
          }, 3000);
        }

      }

    },
    // 转换时间
    timestampToTime (timestamp) {
      let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = date.getDate() + ' ';
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return Y + M + D + h + m + s;
    },
  }
}

</script>
<style lang='less' scoped>
@import '../../../style/mouse.less';
.el-card {
  margin-right: 30px;
  margin-top: 10px;
  position: relative;
}
.el-pagination {
  display: inline-block;
  position: relative;
  right: -50%;
  transform: translate(-50%);
}
.card-body-title {
  display: inline-block;
  border-left: 5px solid #409eff;
  padding-left: 10px;
  margin-bottom: 20px;
}
</style>
