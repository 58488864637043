<!-- 学情IMMEX和K12部分 -->
<template>
  <div class="immex-body">
    <span class="immex-title">学生概况&班级效率效果图</span>
    <el-table :data="tableData" style="width: 100%" border @row-click="clickTable">
      <el-table-column prop="userId" label="ID"></el-table-column>
      <el-table-column prop="userName" label="用户名"></el-table-column>
      <el-table-column prop="fullName" label="姓名"></el-table-column>
      <el-table-column prop="psname" label="变式名称" v-if="activeName=='IMMEX'"></el-table-column>
      <el-table-column prop="startTime" label="起始时间" v-if="activeName=='IMMEX'"></el-table-column>
      <el-table-column prop="finishTime" label="结束时间" v-if="activeName=='IMMEX'"></el-table-column>
      <el-table-column prop="sum" label="活动数量" v-if="activeName!=='IMMEX'"></el-table-column>
      <el-table-column prop="unfinished" label="未完成数量" v-if="activeName!=='IMMEX'"></el-table-column>
      <el-table-column prop="accomplish" label="完成数量" v-if="activeName!=='IMMEX'"></el-table-column>
      <el-table-column prop="avgEfficiency" label="平均效率" v-if="activeName=='IMMEX'"></el-table-column>
      <el-table-column prop="avgSteps" label="平均步长" v-if="activeName=='IMMEX'"></el-table-column>
      <el-table-column prop="avgLoops" label="平均回路数" v-if="activeName=='IMMEX'"></el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      :page-size="pagesize"
      :total="tableDataTotal"
      @current-change="clickCurrentPage"
    ></el-pagination>
    <div v-if="activeName!=='IMMEX'" class="analysis">
      <span class="immex-title">不同活动完成率分析</span>
      <el-date-picker
        v-model="dataValue"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        size="mini"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        @change="changeDate"
      ></el-date-picker>
    </div>
    <span class="immex-span" v-if="activeName=='IMMEX'">学生效果效率统计</span>
    <div
      ref="IMMEXconversion"
      :style="{width: '100%', height: '500px',margin:'20px 0 0 0'}"
      v-if="activeName=='IMMEX'&&showImmex"
    ></div>
    <el-empty description="该活动暂未有人完成!" v-if="!showImmex"></el-empty>
    <div ref="K12conversion" :style="{width: '100%', height: '500px'}" v-if="activeName!=='IMMEX'"></div>
  </div>
</template>

<script>
import ecStat from 'echarts-stat';
import { ActivityPercentageList } from '@/api/api'
export default {
  props: {
    activeName: { type: String },
    k12Situation: { type: Object, default: () => { } },
    ImmexSituation: { type: Object, default: () => { } },
    searchClassId: { type: Number, default: null },
    paramObj: { type: Object, default: () => { } },
  },
  data () {
    return {
      tableData: [],//表格数据
      tableDataTotal: null,
      subjectOptions: [{
        value: 1,
        label: '数学'
      }, {
        value: 2,
        label: '英语'
      }, {
        value: 3,
        label: '语文'
      }],
      subjectValue: '',//选中的学科
      problemValue: '',//暂未启用
      problemOptions: [], //暂未启用
      chart: null,//imx图表载体
      K12chart: null,//k12图表载体
      pagesize: 10,
      currentPage: 1,
      dataValue: [],//选中的日期
      //不能选择当前日期之后的时间
      pickerOptions: {//日期限制
        disabledDate (time) {
          return time.getTime() > Date.now();
        }
      },
      K12Histogram: null,//K12柱状图数据
      immexEchartsData: [],//imx图表数据
      showImmex: true,//是否展示imx
    }
  },
  watch: {
    activeName (val, old) {
      if (val !== old) {
      }
    },
    k12Situation (val, old) {
      if (val !== old) {
        this.tableData = val.list
        if (val.list.length > 0) {
          this.tableDataTotal = val.list[0].total
          this.loadK12Histogram(this.tableData[0].classId)
        }
      }
    },
    ImmexSituation (val, old) {
      if (val !== old) {
        this.tableData = val.list
        this.tableDataTotal = val.total
        this.showImmex = true
        setTimeout(() => {
          if (this.$refs.IMMEXconversion) {
            this.immexEcharts()
          }
        }, 300);
      }
    },
    paramObj (val, old) {
      if (val !== old) {
        this.showImmex = true
        setTimeout(() => {
          if (this.$refs.IMMEXconversion) {
            this.immexEcharts()
          }
        }, 300);
      }
    }
  },
  components: {},
  created () {
    this.getNowTime()
  },
  methods: {
    clickCurrentPage (val) {
      this.$emit('totalCurrentPage', val)
    },
    // 点击表格进入详情
    clickTable (val) {
      if (val.finishTime && this.activeName == "IMMEX") {
        this.$router.push({
          name: "chartIMMEXDetail",
          params: {
            paramObj: val
          }
        });
      } else if (!val.finishTime && this.activeName == "IMMEX") {
        this.$message.error('该学生还未答题!');
      }
      if (this.activeName !== "IMMEX") {
        this.$router.push({ path: '/chartStudents', query: val })
      }
    },
    immexEcharts () {
      this.chart = this.$echarts.init(this.$refs.IMMEXconversion)
      var option;
      option = {
        tooltip: {
          type: 'item',
          formatter: function (params) {
            return (
              "<b>" +
              params.name +
              "</b> <br />有效性: " + (params.value[0] != null ? (params.value[0] + "%") : '--') +
              "</b> <br />有效率: " + (params.value[1] != null ? (params.value[1] + "%") : '--')
            );
          },
        },
        xAxis: {
          max: 100,
          splitLine: {
            show: true
          }
        },
        yAxis: {
          max: 100,
          splitLine: {
            show: true
          }
        },
        series: {
          type: 'scatter',
          name: '',
          symbolSize: 15,
          itemStyle: {
            borderColor: '#555'
          },
          label: {
            show: true,
            position: 'right',
            formatter: function (params) {
              return params.name
            }
          },
          data: [],
          markLine: {
            silent: true, // 是否不响应鼠标事件
            precision: 2, // 精度
            symbol: 'none',
            lineStyle: {
              type: 'solid',
              color: 'rgb(110 112 121)'
            },
            label: {
              show: false,
            },
            data: [
              {
                name: 'y', yAxis: ''
              },
              {
                name: 'x', xAxis: ''
              }
            ]
          },
        }
      };
      let params = {
        "activeId": this.paramObj.activeId,
        "caseId": this.paramObj.caseId,
        "caseType": 1,
        "isComplete": 1,
        "taskId": this.paramObj.taskId,
        "classId": this.paramObj.classId
      }
      this.immexEchartsData = []
      this.$api.page.getAllEfficiency(params).then((res) => {
        if (res.data.data.userData.length > 0) {
          let arr = res.data.data.userData
          let itemColor = ''
          arr.forEach((item, i) => {
            if (item.isHighlight == 1) {
              itemColor = 'red'
            } else {
              itemColor = 'none'
            }
            this.immexEchartsData.push({
              name: item.userFullName,
              activeId: item.activeId,
              caseId: item.caseId,
              isComplete: 1, //现在还没有 8-9
              taskId: item.taskId,
              userId: item.userId,
              classId: this.searchClassId,
              value: [item.effectiveness ? (item.effectiveness * 100).toFixed(2) : null, item.efficiency ? (item.efficiency * 100).toFixed(2) : null],
              itemStyle: {
                color: itemColor
              }
            })
          });
          this.$set(option.series, 'data', this.immexEchartsData);
          option.series.markLine.data[1].xAxis = (res.data.data.avgEffectiveness * 100).toFixed(0)
          option.series.markLine.data[0].yAxis = (res.data.data.avgEfficiency * 100).toFixed(0)
          this.chart.setOption(option);
          this.$nextTick(() => {
            this.chart.on('click', params => {
              params.data['fullName'] = params.data.name
              this.$router.push({
                name: "chartIMMEXDetail",
                params: {
                  paramObj: params.data
                }
              });

            })
          })
        } else {
          this.$set(option.series, 'data', []);
          this.chart.clear()
          this.showImmex = false
        }

      })
    },
    // 获取K12的柱状图数据
    loadK12Histogram (val) {
      this.dataValue[0] = this.dataValue[0] + ' ' + '00:00:00'
      this.dataValue[1] = this.dataValue[1] + ' ' + '23:59:59'
      let param = {
        "id": val,
        "startTime": this.dataValue[0],//时间范围
        "endTime": this.dataValue[1]
      }
      ActivityPercentageList(param).then(res => {
        let arr = []
        let arr1 = []
        res.data.reverse()
        res.data.forEach((item, index) => {
          arr1.push(item.name)
          arr.push(item.percentageComplete * 100)
        })
        this.K12Histogram = res.data
        this.K12Echarts(arr, arr1)
      })
    },
    K12Echarts (arr, arr1) {
      if (this.$refs.K12conversion) {
        this.$nextTick(() => {
          this.K12chart = this.$echarts.init(this.$refs.K12conversion);
          var option;
          option = {
            xAxis: {
              type: 'category',
              data: arr1
            },
            yAxis: {
              type: 'value',
              max: 100
            },
            series: [{
              barMaxWidth: 150,
              data: arr,
              type: 'bar'
            }]
          };
          this.K12chart.setOption(option);
          // 柱状图的点击事件
          this.K12chart.on('click', params => {
            this.K12Histogram[params.dataIndex]['classId'] = this.tableData[0].classId
            this.$router.push(`/chartK12Detail?searchInfo=${JSON.stringify(this.K12Histogram[params.dataIndex])}`)
          })

        });


      }
    },
    // 显示默认日期
    getNowTime () {
      let arr = []
      var now = new Date()
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      arr.push(this.formatDate(start))
      arr.push(this.formatDate(now))
      this.dataValue = arr
    },
    // 选中日期
    changeDate () {
      this.loadK12Histogram(this.tableData[0].classId)
    },
    // 格式化日期
    formatDate (date) {
      var myyear = date.getFullYear();
      var mymonth = date.getMonth() + 1;
      var myweekday = date.getDate();

      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    }
  }
}

</script>
<style lang='less' scoped>
@import '../style/chartImmex.less';
@import '../../../style/mouse.less';
</style>
