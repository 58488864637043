<!-- 学情分析 -->
<template>
  <div style="height:100%">
    <!-- K12老师 -->
    <echartScreen
      @searchClass="searchClass"
      @searchCaseId="searchCaseId"
      @statistics_type="statistics_type"
      @class_info="class_info"
    ></echartScreen>
    <!-- 任务概况 -->
    <div class="card-header">
      <el-card class="box-card" @click.native="noCompleted">
        <div class="card-icon">
          <img src="../../assets/icon/surface.png" alt />
        </div>
        <span class="text">
          {{survey.underway||0}}
          <i>任务进行中</i>
        </span>
      </el-card>
      <el-card class="box-card" @click.native="toStart">
        <div class="card-icon">
          <i class="el-icon-s-platform"></i>
        </div>
        <span class="text">
          {{survey.delay||0}}
          <i>任务待开始</i>
        </span>
      </el-card>
      <el-card class="box-card" @click.native="completed">
        <div class="card-icon">
          <i class="el-icon-s-order"></i>
        </div>
        <span class="text">
          {{survey.accomplish||0}}
          <i>任务已完成</i>
        </span>
      </el-card>
      <el-card class="box-card" @click.native="allActive">
        <div class="card-icon">
          <i class="el-icon-notebook-1"></i>
        </div>
        <span class="text">
          {{survey.allTask||0}}
          <i>全部任务</i>
        </span>
      </el-card>
    </div>
    <!-- K12学情 -->
    <div v-if="statistics_page!==2">
      <el-tabs type="border-card" class="tabs_out">
        <el-tab-pane label="学生概况">
          <!-- immex和k12数据 -->
          <div
            class="card-footer"
            v-fence="{data: '', functions:['K12学生概况&班级效率效果图']}"
            v-if="statistics_page==0"
          >
            <el-card class="box-card" style="border:none;box-shadow:none">
              <el-tabs v-model="activeName" type="card" @tab-click="clickTab(activeName)">
                <el-tab-pane label="K12" name="K12">
                  <chartImmex :k12Situation="k12Situation" @totalCurrentPage="totalCurrentPage"></chartImmex>
                </el-tab-pane>
              </el-tabs>
            </el-card>
          </div>
          <div
            class="card-footer"
            v-fence="{data: '', functions:['学生概况&班级效率效果图']}"
            v-if="statistics_page==1"
          >
            <el-card class="box-card" style="border:none;box-shadow:none">
              <el-tabs v-model="activeName" type="card" @tab-click="clickTab(activeName)">
                <el-tab-pane label="IMMEX" name="IMMEX">
                  <chartImmex
                    :activeName="activeName"
                    :ImmexSituation="ImmexSituation"
                    :searchClassId="searchClassId"
                    :paramObj="paramObj"
                    @totalCurrentPage="totalCurrentPage"
                    v-if="'IMMEX' == activeName && immexFlag"
                  ></chartImmex>
                </el-tab-pane>
              </el-tabs>
            </el-card>
          </div>
        </el-tab-pane>
        <el-tab-pane label="班级变式统计" v-if="statistics_page==0">
          <!-- 班级变式统计 -->
          <div v-fence="{data: '', functions:['K12学情详情']}">
            <classKnowledge :loadclassId="searchClassId"></classKnowledge>
          </div>
        </el-tab-pane>
        <el-tab-pane label="班级活动统计" v-if="statistics_page==0">
          <!-- 活动中变式的完成情况 -->
          <div v-fence="{data: '', functions:['K12学情详情']}">
            <activityPerformance :loadclassId="searchClassId" :class_son_info="class_son_info"></activityPerformance>
          </div>
        </el-tab-pane>
        <el-tab-pane label="章节变式统计" v-if="statistics_page==0">
          <!-- 学生章节变式统计 -->
          <div v-fence="{data: '', functions:['K12学情详情']}">
            <studentVersionTable :class_son_info="class_son_info"></studentVersionTable>
          </div>
        </el-tab-pane>
        <el-tab-pane label="积分统计">
          <!-- 积分排行榜 -->
          <div class="card-body">
            <el-card class="box-card" style="border:none;box-shadow:none">
              <span class="card-body-title">班级积分统计</span>
              <el-table
                :data="integralData.list"
                style="width: 100%"
                @cell-click="clickIntegralData"
                v-loading="loading"
                element-loading-text="拼命统计中"
              >
                <el-table-column prop="id" label="排序编号"></el-table-column>
                <el-table-column prop="fullName" label="姓名"></el-table-column>
                <el-table-column prop="summary" label="累计汇总积分"></el-table-column>
                <el-table-column prop="accomplish" label="完成积分（累计）"></el-table-column>
                <el-table-column prop="star" label="明星积分（累计）"></el-table-column>
                <el-table-column prop="win" label="优胜积分（累计）"></el-table-column>
                <el-table-column prop="help" label="帮扶积分（累计）"></el-table-column>
              </el-table>
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page="currentPageIntegral"
                :page-size="pagesize"
                :total="integralDataTotal"
                @current-change="changeIntegralData"
              ></el-pagination>
            </el-card>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-dialog :title="integralUserName" :visible.sync="integralDetails">
        <el-table :data="gridData" height="288" @row-click="integralJump">
          <el-table-column property="title" label="活动名称"></el-table-column>
          <el-table-column property="accomplish" label="完成积分"></el-table-column>
          <el-table-column property="star" label="明星积分"></el-table-column>
          <el-table-column property="win" label="优胜积分"></el-table-column>
          <el-table-column property="help" label="帮扶积分"></el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="integralDialog.currentPageIntegral"
          :page-size="integralDialog.pagesize"
          :total="integralDialog.integralDataTotal"
          @current-change="integralDialogChange"
        ></el-pagination>
      </el-dialog>
    </div>
    <!-- 标签学情 -->
    <div v-if="statistics_page==2">
      <tagStatistics :searchClassId="searchClassId" :class_son_info="class_son_info"></tagStatistics>
    </div>
  </div>
</template>

<script>
import chartImmex from '../chart-manage/components/chartImmex.vue'
import echartScreen from '../chart-manage/components/echartScreen.vue'
import classKnowledge from '../chart-manage/components/classKnowledge.vue'
import activityPerformance from '../chart-manage/components/activityPerformance.vue'
import tagStatistics from '../chart-manage/components/tagStatistics.vue'
import studentVersionTable from '../chart-manage/components/studentVersionTable.vue'
import { K12Analysis, getUserImmxStatisticsData, taskAnalysis, analysisSourceIntegral, IntegralStatistics } from '@/api/api'

export default {
  data () {
    return {
      activeName: 'K12',//表格标题
      activeName1: 'IMMEX',//表格标题
      searchClassId: null,//搜索的班级id
      k12Situation: {},//K12数据
      ImmexSituation: {},//IMMEX的学生概况&班级效率效果图
      survey: {},//任务概况数据
      integralData: [],//积分表数据
      pagesize: 10,//表格分页
      currentPage: 1,//表格分页
      currentPageIntegral: 1,//表格分页
      immexFlag: false,//是否显示imx
      paramObj: {},
      integralDataTotal: null,//弹框的分页
      integralDetails: false,//是否显示弹框
      integralUserName: '',//弹框标题
      gridData: [],//弹框表格的数据
      integralDialog: {//弹框的分页
        currentPageIntegral: 1,
        pagesize: 5,
        integralDataTotal: null
      },
      statistics_page: null,//0 K12, 2 标签
      studentDetails: null,//学生详情
      class_son_info: {},//班级信息
      loading: false,//加载提示
    }
  },
  components: {
    chartImmex,
    echartScreen,
    classKnowledge,
    activityPerformance,
    tagStatistics,
    studentVersionTable
  },
  created () {
  },
  mounted () {
    // this.immexLoad()// 查询IMMEX的学生概况&班级效率效果图
  },
  methods: {
    // 班级信息
    class_info (val) {
      this.class_son_info = val
    },
    // 标签学情页
    statistics_type (val) {
      setTimeout(() => {
        if (val == 0) {
          this.activeName = 'K12'
        } else if (val == 1) {
          this.activeName = 'IMMEX'
          this.immexFlag = true
        }
      }, 500);

      this.statistics_page = val
    },
    // 积分详情分页
    integralDialogChange (val) {
      this.integralDialog.currentPageIntegral = val
      this.loadIntegral(this.studentDetails)
    },
    // 积分详情跳转
    integralJump () {

    },
    // 打开排行榜
    clickIntegralData (val) {
      this.integralDialog.currentPageIntegral = 1
      this.studentDetails = val
      this.loadIntegral(val)
      this.integralDetails = true
      this.integralUserName = val.fullName
    },
    // 积分详情
    loadIntegral (val) {
      this.gridData = []
      let param = {
        "pageNum": this.integralDialog.currentPageIntegral,
        "pageSize": this.integralDialog.pagesize,
        "classId": this.searchClassId,
        "userId": val.userId
      }
      analysisSourceIntegral(param).then(res => {
        if (res.code == 0) {
          this.gridData = res.data.list
          this.integralDialog.integralDataTotal = res.data.total
        }
      })
    },
    // 积分表分页
    changeIntegralData (val) {
      this.currentPageIntegral = val
      this.integral()
    },
    // 未完成跳转
    noCompleted () {
      this.$router.push({ path: '/activity', query: { 'idType': '未完成', 'classId': this.searchClassId } })
    },
    toStart () {
      this.$router.push({ path: '/activity', query: { 'idType': 'all', 'classId': this.searchClassId } })
    },
    completed () {
      this.$router.push({ path: '/activity', query: { 'idType': '已完成', 'classId': this.searchClassId } })
    },
    allActive () {
      this.$router.push({ path: '/activity', query: { 'idType': 'all', 'classId': this.searchClassId } })
    },
    // 积分统计
    integral () {
      this.loading = true
      let param = {
        "classId": this.searchClassId,
        'pageNum': this.currentPageIntegral,
        'pageSize': this.pagesize
      }
      IntegralStatistics(param).then(res => {
        this.loading = false
        if (res.code == 0) {
          res.data.list.map((item, index) => {
            item['id'] = item.id
          })
          this.integralData = res.data
          this.integralDataTotal = res.data.total
        }
      })
    },
    // 班级内任务概况
    taskSurvey () {
      taskAnalysis({ "classId": this.searchClassId }).then(res => {
        if (res.data) {
          this.survey = res.data
        } else {
          this.survey = {}
        }

      })
    },
    clickTab (val) {
      this.currentPage = 1
      if (val == 'K12') {
        this.K12Load()
      } else {
        this.currentPage = 1
        // 暂时去掉
        this.immexLoad()
        this.paramObj = { 'a': 1 }
      }
    },
    searchClass (val) {
      this.searchClassId = val
      this.taskSurvey()
      switch (this.statistics_page) {
        case 0://K12
          this.activeName = 'K12'
          this.K12Load()
          this.integral()
          break;
        case 1://immex
          this.immexLoad()
          this.immexFlag = true
          this.integral()
          break;
        case 2:
          break;
      }
    },
    searchCaseId (obj) {
      this.immexFlag = true
      this.paramObj = obj;
    },
    // 查询k12数据
    K12Load () {
      let param1 = {
        "pageNum": this.currentPage,
        "pageSize": null,
        "classId": this.searchClassId
      }
      let total = []
      K12Analysis(param1).then(res => {
        if (res.data) {
          total.push(res.data.total)
        }

      })
      let param = {
        "pageNum": this.currentPage,
        "pageSize": this.pagesize,
        "classId": this.searchClassId
      }
      K12Analysis(param).then(res => {
        if (res.data) {
          res.data.list.map(item => {
            item.beginTime = this.dateFormat(item.beginTime)
            item.endTime = this.dateFormat(item.endTime)
            item['classId'] = this.searchClassId
          })
          this.k12Situation = res.data
        }
      })
    },
    // immex的页码
    totalCurrentPage (val) {
      this.currentPage = val
      // 暂时去掉
      this.immexLoad()
      this.K12Load()
    },
    // 查询IMMEX的学生概况&班级效率效果图
    immexLoad () {
      let param = {
        "minGradeType": 5,  //----必须
        "pageNum": this.currentPage, //----必须第一页
        "pageSize": this.pagesize, //----必须每页10条
        "subjectType": 12, //----- 必须
        'classId': this.searchClassId
      }
      getUserImmxStatisticsData(param).then(res => {
        if (res) {
          res.data.list.map(item => {
            if (item.startTime) {
              item.startTime = this.dateFormat(item.startTime)
            }
            if (item.finishTime) {
              item.finishTime = this.dateFormat(item.finishTime)
            }
          })
          this.ImmexSituation = res.data
        }
      })
    },
    setColSpan: function () {
      var x = document.getElementsByClassName("el-table__header")[1].rows[0].cells
      x[1].colSpan = 4
      x[2].style.display = 'none'
      var x1 = document.getElementsByClassName("el-table__header")[1].rows[0].cells[0]
      x1.rowSpan = 2
      x1[2].style.display = 'none'
    },
    // 转换时间
    dateFormat (time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    },
  }
}

</script>
<style lang='less' scoped>
@import '../../style/chartManage/index.less';
@import './style/index.less';
@import '../../style/mouse.less';
.tabs_out {
  margin-top: 10px;
}
</style>
