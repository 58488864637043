<!-- 活动完成情况 -->
<template>
  <div>
    <el-card style="border:none;box-shadow:none">
      <span class="card-body-title">班级活动统计</span>
      <span>请选择活动：</span>
      <el-select v-model="activityValue" placeholder="请选择需要分析的活动" size="mini">
        <el-option
          v-for="item in activityOptions"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="拼命统计中"
      >
        <el-table-column prop="caseSN" label="变式名称">
          <template slot-scope="{row}">
            <div @click.stop="click_special(row,1)">{{row.caseSN}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="participationCount" label="参与人数"></el-table-column>
        <el-table-column prop="accomplishCount" label="已完成人数"></el-table-column>
        <el-table-column prop="unfinishedCount" label="未完成人数">
          <template scope="scope">
            <el-popover
              placement="bottom"
              title="未完成人员"
              width="300"
              trigger="click"
              :content="scope.row.unfinishedName"
            >
              <el-button
                slot="reference"
                style="border:none;background:rgba(0,0,0,0)"
              >{{scope.row.unfinishedCount}}</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="onceCorrectCount" label="一次正确人数">
          <template scope="scope">
            <el-popover
              placement="bottom"
              title="一次正确人员"
              trigger="click"
              :content="scope.row.onceCorrectName"
            >
              <el-button
                slot="reference"
                style="border:none;background:rgba(0,0,0,0)"
              >{{scope.row.onceCorrectCount}}</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="twiceCorrectCount" label="两次才正确人数">
          <template scope="scope">
            <el-popover
              placement="bottom"
              title="两次才正确人员"
              trigger="click"
              :content="scope.row.twiceCorrectName"
            >
              <el-button
                slot="reference"
                style="border:none;background:rgba(0,0,0,0)"
              >{{scope.row.twiceCorrectCount}}</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="twiceIncorrectCount" label="两次都错人数">
          <template scope="scope">
            <el-popover
              placement="bottom"
              title="两次都错人员"
              trigger="click"
              :content="scope.row.twiceIncorrectName"
            >
              <el-button
                slot="reference"
                style="border:none;background:rgba(0,0,0,0)"
              >{{scope.row.twiceIncorrectCount}}</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="accuracy" label="单个变式内化率"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              split-button
              type="primary"
              @click="operation('new_activities',scope.row)"
              @command="click_dropdown(scope.row,$event)"
            >
              针对性布置
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0" :disabled="!scope.row.unfinishedCount">未完成</el-dropdown-item>
                <el-dropdown-item command="1" :disabled="!scope.row.twiceIncorrectCount">二次做错</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 创建活动弹窗 -->
    <addActivity
      :show_addActivity="show_addActivity"
      :addActivityObj="addActivityObj"
      :class_info="class_info"
    ></addActivity>
    <!-- 学生答题的详情 -->
    <el-dialog :title="dialog_title" :visible.sync="dialogTableVisible" width="75%">
      <el-dropdown
        split-button
        type="primary"
        @click="exportExcel('2')"
        @command="exportExcel($event)"
      >
        导出该次变式EXCEL
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">导出全部变式EXCEL</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-table
        :data="gridData"
        v-loading="student_loading"
        element-loading-text="拼命统计中"
        @sort-change="click_sort"
      >
        <el-table-column property="fullName" label="用户名"></el-table-column>
        <el-table-column property="taskCount" label="布置次数"></el-table-column>
        <el-table-column property="createTime" label="布置时间"></el-table-column>
        <el-table-column property="userAnswers" label="本次内化率" sortable></el-table-column>
        <el-table-column v-for="(item,index) in tableArr_student" :key="index" :label="item">
          <template slot-scope="{row}">
            <div
              v-for="(itm,inx) in row.children"
              :key="inx"
              v-if="item == itm.name"
            >{{itm.title==0?'×':'√'}}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChangeDetails"
        :page-size="pageDetails.pageSize"
        layout="total, prev, pager, next"
        :total="pageDetails.total"
        background
        style="margin-top:10px"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { activeList, StudentStudyDetail, ResultByTaskId } from '@/api/api'
import addActivity from '../../../components/addActivity/index.vue'
import { dataConversionUtil } from '../../../util/data2Excel.js'
export default {
  props: {
    loadclassId: {
      type: Number
    },
    class_son_info: {
      tyoe: Object
    }
  },
  data () {
    return {
      tableData: [],//表格数据
      activityOptions: [],//活动下拉框数据
      activityValue: '',//活动下拉框
      classId: '',//父组件传进来的班级ID
      gridData: [],//学生答题详情表
      tableArr_student: [],//表格中的子表头
      dialogTableVisible: false,
      pageDetails: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      select_special: {},//选中的变式数据
      loading: false,//加载提示
      student_loading: false,//第二张表加载提示
      show_addActivity: false,//是否显示新建活动弹窗
      addActivityObj: {},//新建活动的参数
      class_info: {},//班级信息
      button_loading: false,//导出加载
      dialog_title: '',//dialog标题
    }
  },
  watch: {
    loadclassId (val, old) {
      if (val !== old) {
        this.classId = val
        this.load_activity()
      }
    },
    class_son_info (val, old) {
      if (val !== old) {
        this.class_info = val
      }
    },
    activityValue (val, old) {
      if (val !== old) {
        // 活动下拉框选中值改变触发
        this.load_StudentStudyDetail()
      }
    }
  },
  components: {
    addActivity
  },
  mounted () {

  },
  methods: {
    exportExcel (val) {
      this.button_loading = true
      // 查询所有学的答题情况
      let param = {
        "pageNum": null,
        "pageSize": null,
        "taskId": this.select_special.taskId,
        'activityId': this.select_special.activeId,
        "classId": this.select_special.classId,
        "sort": 0,
        "singleORall": Number(val),//1 查所有  2 查当前
      }
      ResultByTaskId(param).then(res => {
        this.button_loading = false
        if (res.code == 0) {
          if (val == '2') {
            res.data.list.map((listValue) => {
              listValue.userAnswers = (listValue.userAnswers * 100).toFixed(2) + '%'
              if (listValue.names) {
                listValue.children = []
                listValue.names.map((nameValue, nameIndex) => {
                  let obj = {}
                  listValue.answers.map((answerValue, answerIndex) => {
                    if (nameIndex == answerIndex) {
                      obj['name'] = nameValue
                      obj['title'] = answerValue
                      listValue.children.push(obj)
                    }
                  })
                })
              }
            })
            let dataList = []
            let arr = [['变式名称', '用户名', '布置次数', '学生做题次数', '布置时间', '本次内化率']]
            res.data.list.forEach((item, index) => {
              dataList[index] = [item.casesName, item.fullName, item.taskCount, item.taskHaveCount, item.createTime, item.userAnswers]
              item.children.forEach((child_item, child_index) => {
                let value = arr[0].find(item => { return item == child_item.name })
                if (!value) {
                  arr[0].push(child_item.name)
                }
                dataList[index].push(child_item.title == 0 ? '×' : '√');
              })
            });
            dataConversionUtil.dataToExcel('答题详情表', arr, dataList)
          } else {
            res.data.list.map((item) => {
              item.map(listValue => {
                listValue.userAnswers = (listValue.userAnswers * 100).toFixed(2) + '%'
                if (listValue.names) {
                  listValue.children = []
                  listValue.names.map((nameValue, nameIndex) => {
                    let obj = {}
                    listValue.answers.map((answerValue, answerIndex) => {
                      if (nameIndex == answerIndex) {
                        obj['name'] = nameValue
                        obj['title'] = answerValue
                        listValue.children.push(obj)
                      }
                    })
                  })
                }
              })
            })
            let arr = [['变式名称', '用户名', '布置次数', '学生做题次数', '布置时间', '本次内化率']]
            res.data.list.forEach((it, index) => {
              let dataList = []
              it.forEach((item, index) => {
                dataList[index] = [item.casesName, item.fullName, item.taskCount, item.taskHaveCount, item.createTime, item.userAnswers]
                item.children.forEach((child_item, child_index) => {
                  let value = arr[0].find(item => { return item == child_item.name })
                  if (!value) {
                    arr[0].push(child_item.name)
                  }
                  dataList[index].push(child_item.title == 0 ? '×' : '√');
                })
              })
              dataConversionUtil.dataToExcel('答题详情表', arr, dataList)
            });
          }
          this.$message.success('导出成功!');
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 表格排序
    click_sort (val) {
      //ascending 倒序
      //descending 正序
      //null 默认
      if (val.column.order == "descending") {
        this.click_special(this.select_special, 0)
      } else if (val.column.order == "ascending" || val.column.order == null) {
        this.click_special(this.select_special, 1)
      }
    },
    handleCurrentChangeDetails (val) {
      this.pageDetails.pageNum = val
      this.click_special(this.select_special, 1)
    },
    // 选中变式
    click_special (val, sort) {
      this.gridData = []
      let activity = this.activityOptions.find(item => { return item.id == this.activityValue })
      this.dialog_title = activity.title + '-' + val.caseSN
      this.student_loading = true
      this.select_special = val
      let param = {
        "pageNum": this.pageDetails.pageNum,
        "pageSize": this.pageDetails.pageSize,
        "taskId": this.select_special.taskId,
        'activityId': this.select_special.activeId,
        "classId": this.select_special.classId,
        "sort": sort,
        "singleORall": 2,//1 查所有  2 查当前
      }
      ResultByTaskId(param).then(res => {
        this.student_loading = false
        if (res.code == 0) {
          res.data.list.map((listValue) => {
            listValue.userAnswers = (listValue.userAnswers * 100).toFixed(2) + '%'
            if (listValue.names) {
              this.tableArr_student = listValue.names
              listValue.children = []
              listValue.names.map((nameValue, nameIndex) => {
                let obj = {}
                listValue.answers.map((answerValue, answerIndex) => {
                  if (nameIndex == answerIndex) {
                    obj['name'] = nameValue
                    obj['title'] = answerValue
                    listValue.children.push(obj)
                  }
                })
              })
            }
          })
          this.gridData = res.data.list
          this.pageDetails.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
      this.dialogTableVisible = true
    },
    load_activity () {
      let param = {
        classId: this.classId,
        pageNum: null,
        pageSize: null,
      }
      activeList(param).then(res => {
        if (res.code == 0) {
          this.activityOptions = res.data.list
          this.activityValue = res.data.list[0].id
          this.load_StudyStatistics()
        }
      })
    },
    load_StudyStatistics () {
    },
    // 针对性图表
    load_StudentStudyDetail () {
      this.loading = true
      let params = {
        "pageNum": null,
        "pageSize": null,
        "classId": this.classId, //班级id
        "activeId": this.activityValue //活动id
      }
      StudentStudyDetail(params).then(res => {
        this.loading = false
        if (res.code == 0) {
          res.data.list.map(accuracy_item => {
            accuracy_item.accuracy = (accuracy_item.accuracy * 100).toFixed(2) + '%'
          })
          console.log(res.data.list)
          this.tableData = res.data.list
        }
      })
    },
    // 一键创建全部
    operation (type, data) {
      let sectionId = data.setId
      let topic = data.caseId
      let userId = data.unfinishedId !== null ? data.unfinishedId + ',' + data.twiceIncorrectId : data.twiceIncorrectId
      this.show_addActivity = !this.show_addActivity
      let obj = {}
      obj['classId'] = this.classId
      obj['userId'] = userId
      obj['sectionId'] = sectionId
      obj['topic'] = topic
      this.addActivityObj = obj
    },
    // 创建未完成,二次错的活动
    click_dropdown (val, type) {
      // 0  未完成
      // 1  二次做错
      let obj = {}
      if (type == 0) {
        obj['classId'] = this.classId
        obj['userId'] = val.unfinishedId
        obj['sectionId'] = val.setId
        obj['topic'] = val.caseId
        this.addActivityObj = obj
      } else if (type == 1) {
        obj['classId'] = this.classId
        obj['userId'] = val.twiceIncorrectId
        obj['sectionId'] = val.setId
        obj['topic'] = val.caseId
        this.addActivityObj = obj
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import '../../../style/mouse.less';
.el-card {
  margin-top: 10px;
  margin-right: 30px;
}
.card-body-title {
  display: inline-block;
  border-left: 5px solid #409eff;
  padding-left: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.el-pagination {
  display: inline-block;
  position: relative;
  right: -50%;
  transform: translate(-50%);
}
/deep/.el-dropdown {
  .el-button-group {
    min-width: 120px;
    .el-button:first-child {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
</style>
